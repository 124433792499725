/////////////
/// CHECK BTAG
/////////////
export const incomeAccessCheckURL = (url) => {
    return url.includes('btag=')
}

/////////////
/// SET BTAG
/////////////
export const incomeAccessSetBtag = (url) => {
    let btag = null;

    if (url.includes('btag=')) {
        btag = url.split('btag=')[1].split('&')[0];
    }

    /**************/
    /** Pepper partners */
    /** PP step 1 */
    try {
        if (btag === 'a_44133b_19017c') {
            try {
                let clickId = window.location.search.split('clickid=')[1].split('&')[0];
                let web = window.location.search.split('web=')[1].split('&')[0];

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Pepper Partners, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Pepper Partners set click id, ${error}`)
    }

    /**************/
    /** Huffson */
    /** step 1 */
    try {
        if (btag === 'a_44248b_19017c') {
            try {
                let clickId = window.location.search.split('clickid=')[1].split('&')[0];
                let web = window.location.search.split('web=')[1].split('&')[0];

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Huffson, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Huffson set click id, ${error}`)
    }

    /**************/
    /** Aslan */
    /** step 1 */
    try {
        if (btag === 'a_44286b_19017c') {
            try {
                let clickId = window.location.search.split('clickid=')[1].split('&')[0];
                //let web = window.location.search.split('web=')[1].split('&')[0];

                btag = btag + '_' + clickId; // '_' + web +
            } catch (error) {
                console.log(`Error, Arslan, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Arslan set click id, ${error}`)
    }

    /**************/
    /** Netherlands */
    /** step 1 */
    try {
        if (btag === 'a_44545b_19017c') {
            try {
                let clickId = window.location.search.split('acid=')[1].split('&')[0];
                let web = window.location.search.split('AffiliateID=')[1].split('&')[0];

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Netherlands, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Netherlands set click id, ${error}`)
    }

    /**************/
    /** Playcash */
    /** step 1 */
    try {
        if (btag === 'a_44390b_19017c' ||
            btag === 'playcash_ca_fb_ios' ||
            btag === 'playcash_ca_fb_android'
        ) {
            try {
                let clickId = window.location.search.split('clickid=')[1].split('&')[0];
                let web = window.location.search.split('web=')[1].split('&')[0];

                //btag = btag + '_' + clickId; // '_' + web +
                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Playcash, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Playcash set click id, ${error}`)
    }

    /**************/
    /** Traffgrunt */
    /** step 1 */
    try {
        if (btag === 'traffgrunt_ca_fbpwa') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                //btag = btag + '_' + clickId; // '_' + web +
                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error,  Traffgrunt, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error,  Traffgrunt set click id, ${error}`)
    }

    /**************/
    /** Traffbraza */
    /** step 1 */
    try {
        if (btag === 'traffbraza_ca_fbpwa') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                //btag = btag + '_' + clickId; // '_' + web +
                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error,  Traffbraza, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error,  Traffbraza set click id, ${error}`)
    }

    /**************/
    /** NGR */
    /** step 1 */
    try {
        if (btag === 'ngr_ca_fbmix' ||
            btag === 'ngr_ca_aso'
        ) {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                //btag = btag + '_' + clickId; // '_' + web +
                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error,  NGR, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error,  NGR set click id, ${error}`)
    }

    /**************/
    /** Black ASO */
    /** step 1 */
    try {
        if (btag === 'blackaso_ca_aso_android') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                //btag = btag + '_' + clickId; // '_' + web +
                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error,  Black ASO, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Black ASO set click id, ${error}`)
    }

    /**************/
    /** GulfStream */
    /** step 1 */
    try {
        if (btag === 'gulfstream_ca_fbpwa') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                //btag = btag + '_' + clickId; // '_' + web +
                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error,  GulfStream, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, GulfStream set click id, ${error}`)
    }

    /**************/
    /** All in */
    /** step 1 */
    try {
        if (btag === 'allin_ca_fbmix') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                //btag = btag + '_' + clickId; // '_' + web +
                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error,  All in, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error,  All in set click id, ${error}`)
    }

    /**************/
    /** CPA Crew */
    /** step 1 */
    try {
        if (btag === 'a_44602b_19017c') {
            try {
                let clickId = window.location.search.split('clickid=')[1].split('&')[0];
                let web = window.location.search.split('web=')[1].split('&')[0];

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, CPA Crew, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, CPA Crew set click id, ${error}`)
    }

    /**************/
    /** Magic Click */
    /** step 1 */
    try {
        if (btag === 'a_43396b_19017c') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Magic Click, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Magic Click set click id, ${error}`)
    }

    /**************/
    /** Offergate */
    /** step 1 */
    try {
        if (btag === 'a_44657b_19017c') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, OfferGate, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, OfferGate set click id, ${error}`)
    }

    /**************/
    /** YepAds */
    /** step 1 */
    try {
        if (btag === 'a_44640b_19017c') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, YepAds, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, YepAds set click id, ${error}`)
    }

    /**************/
    /** YepAds RS */
    /** step 1 */
    try {
        if (btag === 'ypds00437RS') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, YepAds RS, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, YepAds RS set click id, ${error}`)
    }
    
    /**************/
    /** Massons */
    /** step 1 */
    try {
        if (btag === 'mssnprtrs4385') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Masson Partners, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Masson Partners set click id, ${error}`)
    }

    /**************/
    /** Adkey */
    /** step 1 */
    try {
        if (btag === 'a_44854b_19077c') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Adkey, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Adkey set click id, ${error}`)
    }

    /**************/
    /** C3PA */
    /** step 1 */
    try {
        if (btag === 'c3pa33890') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, C3PA, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, C3PA set click id, ${error}`)
    }

    /**************/
    /** Oksana */
    /** step 1 */
    try {
        if (btag === 'a_43426b_19077c') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Oksana, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Oksana set click id, ${error}`)
    }

    /**************/
    /** CPA_BROS */
    /** step 1 */
    try {
        if (btag === 'cpab4390455') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, CPA_BROS, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, CPA_BROS set click id, ${error}`)
    }

    /**************/
    /** Triangle */
    /** step 1 */
    try {
        if (btag === 'tria673499') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Triangle, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Triangle set click id, ${error}`)
    }

    /**************/
    /** Gamebeat */
    /** step 1 */
    try {
        if (btag === 'gamb88490') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Gamebeat, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Gamebeat set click id, ${error}`)
    }

    /**************/
    /** Sharklink */
    /** step 1 */
    try {
        if (btag === 'shark3487') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Sharklink, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Sharklink set click id, ${error}`)
    }

    /**************/
    /** Quad */
    /** step 1 */
    try {
        if (btag === 'quad777') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Quad, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Quad set click id, ${error}`)
    }

    /**************/
    /** Traffic Devils */
    /** step 1 */
    try {
        if (btag === 'tfd66653') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, Traffic Devils, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, Traffic Devils set click id, ${error}`)
    }

    /**************/
    /** AFFPRO */
    /** step 1 */
    try {
        if (btag === 'affpro32551') {
            try {
                let clickId = window.location.search.split('clickid=')[1]?.split('&')[0] || '0';
                let web = window.location.search.split('web=')[1]?.split('&')[0] || '0';

                btag = btag + '_' + web + '_' + clickId;
            } catch (error) {
                console.log(`Error, AFFPRO, adding clickid ${error}`)
            }
        }
    } catch (error) {
        console.log(`Error, AFFPRO set click id, ${error}`)
    }


    /**************/

    let cookie = "btag" + "=" + encodeURIComponent(btag);
    let daysToLive = 30; // 3650; // 10 years default

    cookie += "; max-age=" + (daysToLive * 24 * 60 * 60);
    document.cookie = cookie + ";path=/";
}

/////////////
/// GET BTAG
/////////////
export const incomeAccessGetBtag = () => {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        if ('btag' == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1].trim());
        }
    }

    return '';
}


////////////////////////////////////////

/////////////
/// GET BTAG
/////////////
// export const incomeAccessGetBtag = (url) => {
//     let btag = null;

//     if (url.includes('btag=')) {
//         btag = url.split('btag=')[1].split('&')[0];
//     }

//     return btag;
// }

///////////////////////
/// Cookie functions
///////////////////////
// function setBtagCookie(value, daysToLive = 3650) { // 10 years default
//     let cookie = "btag" + "=" + encodeURIComponent(value);

//     if (typeof daysToLive === "number") {
//         cookie += "; max-age=" + (daysToLive * 24 * 60 * 60);
//         document.cookie = cookie + ";path=/";
//     }
// }


// function getBtagCookie(name) {
//     let cookieArr = document.cookie.split(";");

//     for (let i = 0; i < cookieArr.length; i++) {
//         let cookiePair = cookieArr[i].split("=");
//         if (name == cookiePair[0].trim()) {
//             return decodeURIComponent(cookiePair[1].trim());
//         }
//     }

//     return undefined;
// }



// function deleteBtagCookie(name) {
//     setCookie(name, '', -1);
// }