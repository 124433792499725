import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../reducers/user';
import { Button, FloatingLabel, Form, Row, Col, Alert, InputGroup, Offcanvas, Spinner } from 'react-bootstrap';
import { dbRegister, dbLogin, dbGetGame, dbGetPromotion, dbCheckEmailExists, dbSendWelcomeEmail, dbGetCountries } from "../utils/db-functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { voluumSendPostback } from "../utils/voluum";
import { incomeAccessGetBtag } from '../utils/income-access';
import { getTrafficSource, getFacebookCookie } from "../utils/traffic-source";
import { Helmet } from "react-helmet-async";
import uniqid from 'uniqid';
import 'dotenv/config';

import flagCanada from "../data/images/flag-canada.png";
import AddressLookupRegForm from "../components/AddressLookupRegForm";
import Loading from "../components/Loading";
import getImageSrc from "../utils/get-image-src";
import arrowEmail from "../data/images/arrow-email.png";
import arrowAddress from "../data/images/arrow-address.png";

export default function RegisterNew() {
    const navigate = useNavigate();
    const location = useLocation();

    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        setTimeout(() => { navigate(location.pathname); }, 300);
    };

    return (
        <Offcanvas show={show} onHide={handleClose} className="align-modal-bottom-center register-canvas" placement={'end'}>
            <Offcanvas.Header closeButton className="register-canvas-header" >
                <h1 className="register-canvas-title">Create new account</h1>
            </Offcanvas.Header>

            <Offcanvas.Body className="register-canvas-body">
                {/* <RegisterForm /> */}
                <RegisterFormNew />
            </Offcanvas.Body>

        </Offcanvas>
    )
}

function RegisterFormNew() {
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.globalContent.data.locale);
    const country = useSelector((state) => state.globalContent.data.country);

    /** Errors */
    const [errors, setErrors] = useState([]);

    /** Form fields */
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('AB');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [countries, setCountries] = useState([]);
    const [countrySelect, setCountrySelect] = useState(country.name);

    /** Refs */
    const progressBar = useRef(null)
    const refEmail = useRef(null)
    const refPassword = useRef(null)
    const refName = useRef(null)
    const refCountry = useRef(null)
    const refPhone = useRef(null)
    const refAddressAutoCanada = useRef(null)
    const refAddressManualCanada = useRef(null)
    const refAddressManualWorld = useRef(null)
    const refCompleteRegistration = useRef(null)

    /** Steps */
    const [step, setStep] = useState('email');
    const [isLoading, setIsLoading] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);

    async function toNextStep(nextStep, ref) {
        setIsLoading(true)

        if (step === 'email') {
            if (isValidEmail(email)) {

                try {
                    const result = await dbCheckEmailExists(email);

                    if (result?.data?.totalDocs > 0) {
                        setErrors([{ message: "Such email already exists." }]);
                        setIsLoading(false);
                        return;
                    }
                }
                catch (error) {
                    setErrors([{ message: "Something went wrong. Please try again" }]);
                    setIsLoading(false);
                    console.log(error)
                    return;
                }

                progressBar.current.classList.remove(`step-email`);
            }
        }

        ref.current.classList.add("animate__fadeOut");
        progressBar.current.classList.add(`step-${nextStep}`);
        setIsValidForm(false)
        setErrors([]);
        setTimeout(() => {
            setStep(nextStep);
            setIsLoading(false);
        }, 500);
    }

    function toPreviousStep(previosStep) {
        progressBar.current.classList.remove(`step-${step}`);
        setStep(previosStep)
    }

    function getAddressFromLookup({ lStreet, lCity, lState, lZip }) {
        setStreet(lStreet);
        setCity(lCity);
        setState(lState);
        setZip(lZip);
        toNextStep('addressManualCanada', refAddressAutoCanada);
    }

    function sendSignUpEventSpudo(user) {
        const acid = user?.btag?.split('a_43990b_19017c_')[1];
        const playerId = user?.id;

        try {
            console.log(`sendSignupEventSpudo: ${playerId}, ${acid}`);
            fetch(`https://www.ka2trk.com/?nid=${playerId}&aid=462&adv_event_id=414&transaction_id=${acid}`);
        } catch (error) {
            console.log(`Error, sendSignupEventSpudo, ${error}`)
        }
    }

    function sendSignUpEventTrafficDevils(user) {
        try {
            const clickId = user?.tsource;

            console.log(`sendSignUpEventTrafficDevils: ${clickId}`);
            fetch(`https://postback.devils-pit.com?to=k&subid=${clickId}&status=lead&from=cherryspins`);
        } catch (error) {
            console.log(`Error, sendSignUpEventTrafficDevils, ${error}`)
        }
    }

    function sendSignUpEventPepperPartners(user) {
        try {
            const clickId = user?.tsource;
            const webId = user?.btag?.split('a_44133b_19017c_')[1];

            console.log(`sendSignUpEventPepperPartners: ${clickId}, ${webId}`);
            fetch(`https://pepperpartners.scaletrk.com/track/goal-by-click-id?goal_alias=registration&click_id=${clickId}&user=${webId}&from=cherryspins`)
        } catch (error) {
            console.log(`Error, sendSignUpEventPepperPartners, ${error}`)
        }
    }

    function sendSignUpEventHuffson(user) {
        try {
            const clickId = user?.tsource;
            const webId = user?.btag?.split('a_44248b_19017c_')[1];

            console.log(`sendSignUpEventHuffson: ${clickId}`);

            fetch(`https://huffson-delivery.com/action?webmaster_id=${webId}&subId=${clickId}&type=registration`);
        } catch (error) {
            console.log(`Error, sendSignUpEventHuffson, ${error}`)
        }
    }

    function sendSignUpEventArslan(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_44248b_19017c_')[1];

            console.log(`sendSignUpEventArslan: ${clickId}`);

            fetch(`https://bigger-bass-splash-ca.xyz/c3151cd/postback?subid=${clickId}&status=lead`);
        } catch (error) {
            console.log(`Error, sendSignUpEventArslan, ${error}`)
        }
    }

    function sendSignUpEventSomeAffiliate(user) {
        try {
            const clickId = user?.tsource;

            console.log(`Advidi: ${clickId}`);

            fetch(`https://pixel.mrntrack.com/offers/5932?transaction_id=${clickId}`);


        } catch (error) {
            console.log(`Error, Advidi, ${error}`)
        }
    }

    function sendSignUpEventPlayCash(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_44248b_19017c_')[1];

            console.log(`sendSignUpEventPlayCash: ${clickId}`);

            fetch(`https://offers-playcash.affise.com/postback?clickid=${clickId}&goal=1&status=1`);
        } catch (error) {
            console.log(`Error, sendSignUpEventPlayCash, ${error}`)
        }
    }

    function sendSignUpEventAllIn(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_44248b_19017c_')[1];

            console.log(`sendSignUpEventAllIn: ${clickId}`);

            fetch(`https://adsp01stats.online/1f1c258/postback?subid=${clickId}&status=lead&payout=0&from=CSPartners`);
        } catch (error) {
            console.log(`Error, sendSignUpEventAllIn, ${error}`)
        }
    }

    function sendSignUpEventBlackASO(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_44248b_19017c_')[1];

            console.log(`sendSignUpEventBlackASO: ${clickId}`);

            fetch(`https://167.235.61.84/da5b22a/postback?subid=${clickId}&status=lead`);
        } catch (error) {
            console.log(`Error, sendSignUpEventBlackASO, ${error}`)
        }
    }

    function sendSignUpEventGulfStream(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_44248b_19017c_')[1];

            console.log(`sendSignUpEventGulfStream: ${clickId}`);

            fetch(`https://offers-gulfstream.affise.com/postback?clickid=${clickId}&goal=reg`);
        } catch (error) {
            console.log(`Error, sendSignUpEventGulfStream, ${error}`)
        }
    }

    function sendSignUpEventTraffgrunt(user) {
        try {
            const clickId = user?.tsource;

            console.log(`sendSignUpEventTraffgrunt: ${clickId}`);

            fetch(`https://traffgrunt.space/e52f0b4/postback?subid=${clickId}&status=lead&payout=0`);
        } catch (error) {
            console.log(`Error, sendSignUpEventTraffgrunt, ${error}`)
        }
    }

    function sendSignUpEventTraffbraza(user) {
        try {
            const clickId = user?.tsource;

            console.log(`sendSignUpEventTraffbraza: ${clickId}`);

            fetch(`https://ommstudia.com/lander/global_postback/index.php?cnv_id=${clickId}&cnv_status=reg_status&payout=0`);
        } catch (error) {
            console.log(`Error, sendSignUpEventTraffbraza, ${error}`)
        }
    }

    function sendSignUpEventNGR(user) {
        try {
            const clickId = user?.tsource;

            console.log(`sendSignUpEventNGR: ${clickId}`);

            fetch(`https://frtnm.postback.alanbase.com/goals/xR5C5fQYXe?click_id=${clickId}&goal=reg&status=pending`);
        } catch (error) {
            console.log(`Error, sendSignUpEventNGR, ${error}`)
        }
    }

    function sendSignUpEventIAffiliate(user) {
        try {
            const clickId = user?.tsource;
            const webId = user?.btag?.split('a_44602b_19017c_')[1];

            console.log(`sendSignUpEventIAffiliate: ${clickId}, ${webId}`);

            fetch(`https://cpacrew.scaletrk.com/track/goal-by-click-id?goal_alias=reg&click_id=${clickId}&adv_user_id=${webId}&adv_track_id=cspartners`);
        } catch (error) {
            console.log(`Error, sendSignUpEventIAffiliate, ${error}`)
        }
    }

    function sendSignUpEventMagicClick(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_43396b_19017c_')[1];

            console.log(`sendSignUpEventMagicClick: ${clickId}`);

            fetch(`https://track.magicclick.partners/track/goal-by-click-id?goal_alias=lead&click_id=${clickId}`);
        } catch (error) {
            console.log(`Error, sendSignUpEventMagicClick, ${error}`)
        }
    }

    function sendSignUpEventOfferGate(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_44657b_19017c_')[1];

            console.log(`sendSignUpEventOfferGate: ${clickId}`);

            fetch(`https://systemgate.pro/api/goal?visitid=${clickId}&goalid=reg&goalstatus=1`);
        } catch (error) {
            console.log(`Error, sendSignUpEventOfferGate, ${error}`)
        }
    }

    function sendSignUpEventYepAds(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_44640b_19017c_')[1];

            console.log(`sendSignUpEventYepAds: ${clickId}`);

            fetch(`https://api.mayeofiuw.com/t/pb?leadid=${clickId}&event=3983`);
        } catch (error) {
            console.log(`Error, sendSignUpEventYepAds, ${error}`)
        }
    }

    function sendSignUpEventMassonPartners(user) {
        try {
            const clickId = user?.tsource;
            //const webId = user?.btag?.split('a_44640b_19017c_')[1];

            console.log(`Masson Partners: ${clickId}`);

            fetch(`https://traff.masons.partners/postback?token=qoqv7Fdh8XdkskLrK0JqV8wMXPFxZd&click_id=${clickId}&event=Registration
            `);
        } catch (error) {
            console.log(`Error, Masson Partners, ${error}`)
        }
    }

    function sendSignUpEventAdkey(user) {
        try {
            const clickId = user?.tsource;

            console.log(`Adkey: ${clickId}`);

            fetch(`https://trck.adkey.agency/7a3d78c/postback?subid=${clickId}&status=lead&from=cherryspins`);
        } catch (error) {
            console.log(`Error, Adkey Partners, ${error}`)
        }
    }

    function sendFBPixelAdkey() {
        try {
            window.fbq('init', '972094264611488');
            window.fbq('track', 'PageView');
            console.log(`Success, sendFBPixelAdkey`);
        } catch (error) {
            console.log(`Error, sendFBPixelAdkey, ${error}`)
        }
    }

    function sendSignUpEventC3PA(user) {
        try {
            const clickId = user?.tsource;

            console.log(`C3PA: ${clickId}`);

            fetch(`https://offers.c3pa.net/postback?clickid=${clickId}&status=2&sum=180`);
        } catch (error) {
            console.log(`Error, C3PA Partners, ${error}`)
        }
    }

    function sendSignUpEventTriangle(user) {
        try {
            const clickId = user?.tsource;

            console.log(`Triangle: ${clickId}`);

            fetch(`https://offers-triangles.affise.com/postback?clickid=${clickId}&goal=1&status=2`);
        } catch (error) {
            console.log(`Error, Triangle, ${error}`)
        }
    }

    function sendSignUpEventGamebeat(user) {
        try {
            const clickId = user?.tsource;

            console.log(`Gamebeat: ${clickId}`);

            fetch(`https://tracker.gambeat.io/p?token=cherryspins&event=Registration&click_id=${clickId}`);
        } catch (error) {
            console.log(`Error, Gamebeat, ${error}`)
        }
    }

    function sendSignUpEventSharklink(user) {
        try {
            const clickId = user?.tsource;

            console.log(`sendSignUpEventSharklink: ${clickId}`);

            fetch(`https://offers-sharklink.affise.com/postback?clickid=${clickId}&goal=reg&status=1
            `);
        } catch (error) {
            console.log(`Error, sendSignUpEventSharklink, ${error}`)
        }
    }

    function sendSignUpEventTFD(user) {
        try {
            const clickId = user?.tsource;

            console.log(`sendSignUpEventTFD: ${clickId}`);

            fetch(`https://postback.devils-pit.com?to=k&subid=${clickId}&status=lead&from=cherryspins`);
        } catch (error) {
            console.log(`Error, sendSignUpEventTFD, ${error}`)
        }
    }

    function sendSignUpEventOksana(user) {
        try {
            const clickId = user?.tsource;

            console.log(`Oksana: ${clickId}`);

            fetch(`https://advertiseru.net/postback/760613f70a4e426b/?token=63a7f2f53f8b4b81f3ed34bb1f418ef8&uid=${clickId}`);
        } catch (error) {
            console.log(`Error, Oksana Partners, ${error}`)
        }
    }

    function sendSignUpEventCPA_BROS(user) {
        try {
            const clickId = user?.tsource;

            console.log(`CPA_BROS: ${clickId}`);

            fetch(`https://pb.brobotx.space/postback?target_path=cherryspins&code=${clickId}&action_alias=reg&secret=g43tgrOn9cWvoo0eeqb3`);
        } catch (error) {
            console.log(`Error, CPA_BROS Partners, ${error}`)
        }
    }

    function sendSignUpEventQuad(user) {
        try {
            const clickId = user?.tsource;

            console.log(`Quad: ${clickId}`);

            fetch(`https://ibsquad.pro/8f7bc20/postback?subid=${clickId}&status=lead&from=CherrySpins`);
        } catch (error) {
            console.log(`Error, Quad, ${error}`)
        }
    }

    function sendSignUpEventAFFPRO(user) {
        try {
            const clickId = user?.tsource;

            console.log(`AFFPRO: ${clickId}`);

            fetch(`https://postback.aff-pro.com/track/conv?click_id=${clickId}&token=4f7ad7af&goal_alias=reg&conv_status=approved`);
        } catch (error) {
            console.log(`Error, AFFPRO, ${error}`)
        }
    }

    function sendPostbackRichads(user) {
        const playerId = user?.id;
        const country = user?.tsource?.split('_richads')[0];

        try {
            console.log(`sendPostbackRichads: ${playerId}, ${country}`);
            fetch(`http://xml.auxml.com/log?action=conversion&key=${playerId}&country=${country}price=0`)
        } catch (error) {
            console.log(`Error, sendPostbackRichads, ${error}`)
        }
    }

    function register() {
        const [firstName, ...rest] = fullName.split(' ');
        const lastName = rest.join(' ');
        const country = countries.filter(country => country.name === countrySelect)[0];
        const countryCode = country.phoneCode;
        const currency = country.defaultCurrency;
        let tsource = getTrafficSource();
        let btag = incomeAccessGetBtag();
        let depositMinAmount = 0;

        if (tsource === 'facebook') {
            const cookie = getFacebookCookie();
            tsource += cookie;
            console.log(tsource)
        }

        /** Traffic devils */
        /** TF step 2 */
        try {
            if (btag?.includes('a_44025b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Traffic devils, step 2, ${error}`)
        }

        /** Pepper partners */
        /** PP step 2 */
        try {
            if (btag?.includes('a_44133b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Pepper partners, step 2, ${error}`)
        }

        /** Huffson */
        /** step 2 */
        try {
            if (btag?.includes('a_44248b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Huffson, step 2, ${error}`)
        }

        /** Arslan */
        /** step 2 */
        try {
            if (btag?.includes('a_44286b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Arslan, step 2, ${error}`)
        }

        /** Netherlands */
        /** step 2 */
        try {
            if (btag?.includes('a_44545b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Netherlands, step 2, ${error}`)
        }

        /** PlayCash */
        /** step 2 */
        try {
            if (btag?.includes('a_44390b_19017c') ||
                btag?.includes('playcash_ca_fb_ios') ||
                btag?.includes('playcash_ca_fb_android')
            ) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, PlayCash, step 2, ${error}`)
        }

        /** NGR */
        /** step 2 */
        try {
            if (btag?.includes('ngr_ca_fbmix') ||
                btag?.includes('ngr_ca_aso')
            ) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, NGR, step 2, ${error}`)
        }

        /** All in */
        /** step 2 */
        try {
            if (btag?.includes('allin_ca_fbmix')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, All in, step 2, ${error}`)
        }

        /** Black ASO */
        /** step 2 */
        try {
            if (btag?.includes('blackaso_ca_aso_android')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Black ASO, step 2, ${error}`)
        }

        /** GulfStream */
        /** step 2 */
        try {
            if (btag?.includes('gulfstream_ca_fbpwa')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, GulfStream, step 2, ${error}`)
        }

        /** Traffgrunt */
        /** step 2 */
        try {
            if (btag?.includes('traffgrunt_ca_fbpwa')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Traffgrunt, step 2, ${error}`)
        }

        /** Traffbraza */
        /** step 2 */
        try {
            if (btag?.includes('traffbraza_ca_fbpwa')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Traffbraza, step 2, ${error}`)
        }

        /** CPA Crew */
        /** step 2 */
        try {
            if (btag?.includes('a_44602b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, I affiliate, step 2, ${error}`)
        }

        /** MagicClick */
        /** step 2 */
        try {
            if (btag?.includes('a_43396b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, MagicClick, step 2, ${error}`)
        }

        /** OfferGate */
        /** step 2 */
        try {
            if (btag?.includes('a_44657b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, OfferGate, step 2, ${error}`)
        }

        /** YepAds */
        /** step 2 */
        try {
            if (btag?.includes('a_44640b_19017c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, YepAds, step 2, ${error}`)
        }

        /** YepAds RS */
        /** step 2 */
        try {
            if (btag?.includes('ypds00437RS')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, YepAds RS, step 2, ${error}`)
        }

        /** Masson Partners */
        /** step 2 */
        try {
            if (btag?.includes('mssnprtrs4385')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, YepAds, step 2, ${error}`)
        }

        /** Adkey */
        /** step 2 */
        try {
            if (btag?.includes('a_44854b_19077c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Adkey, step 2, ${error}`)
        }

        /** C3PA */
        /** step 2 */
        try {
            if (btag?.includes('c3pa33890')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, C3PA, step 2, ${error}`)
        }

        /** Oksana */
        /** step 2 */
        try {
            if (btag?.includes('a_43426b_19077c')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Oksana, step 2, ${error}`)
        }

        /** CPA_BROS */
        /** step 2 */
        try {
            if (btag?.includes('cpab4390455')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, CPA_BROS, step 2, ${error}`)
        }

        /** Triangle */
        /** step 2 */
        try {
            if (btag?.includes('tria673499')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Triangle, step 2, ${error}`)
        }

        /** Gamebeat */
        /** step 2 */
        try {
            if (btag?.includes('gamb88490')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Gamebeat, step 2, ${error}`)
        }

        /** Sharklink */
        /** step 2 */
        try {
            if (btag?.includes('shark3487')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Sharklink, step 2, ${error}`)
        }

        /** Traffic Devils */
        /** step 2 */
        try {
            if (btag?.includes('tfd66653')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Traffic Devils, step 2, ${error}`)
        }

        /** Quad */
        /** step 2 */
        try {
            if (btag?.includes('quad777')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, Quad, step 2, ${error}`)
        }

        /** AFFPRO */
        /** step 2 */
        try {
            if (btag?.includes('affpro32551')) {
                let btagParams = btag.split('_');
                /** Tsource gets assigned the click id */
                tsource = btagParams.pop();
                /** Btag is left only with web parameter */
                btag = btagParams.join('_');
            }
        } catch (error) {
            console.log(`Error, AFFPRO, step 2, ${error}`)
        }

        /*************************/
        /** Set mde */
        /************************/

        const data = {
            email: email,
            password: password,
            /** Information */
            firstName: firstName,
            lastName: lastName,
            street: street,
            city: city,
            state: state,
            zip: zip,
            country: country.id,
            playerCountry: country.name,
            phone: countryCode + phone,
            /** Cashier */
            currency: currency.id,
            playerCurrency: currency.code,
            wagerBalance: 0,
            withdrawableBalance: 0,
            depositsMade: 0,
            /** Bonus */
            bonus: [],
            /** Other */
            dob: '',
            gender: 'm',
            bonusesClaimed: '',
            lastPlayedGames: '',
            btag: btag,
            tsource: tsource,
            depositMinAmount: depositMinAmount
        }

        if (process.env.REACT_APP_ALLOW_REGISTRATION === 'true') {
            dbRegister(data).then(res => {
                if (!res.data.doc) { return; }

                dbLogin(data.email, data.password).then(res => {
                    if (!res.data.user) { return; }

                    /*******************/
                    /** Sign up postbacks */
                    try {
                        /******************/
                        /** Spudo */
                        if (res?.data?.user?.btag?.includes('a_43990b_19017c')) {
                            sendSignUpEventSpudo(res?.data?.user);
                        }

                        if (res?.data?.user?.tsource?.includes('richads')) {
                            sendPostbackRichads(res?.data?.user);
                        }

                        /** Traffic devils */
                        /** TF step 3 */
                        if (res?.data?.user?.btag?.includes('a_44025b_19017c')) {
                            sendSignUpEventTrafficDevils(res?.data?.user);
                        }

                        /** Pepper partners */
                        /** PP step 3 */
                        if (res?.data?.user?.btag?.includes('a_44133b_19017c')) {
                            sendSignUpEventPepperPartners(res?.data?.user);
                        }

                        /** Huffson */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_44248b_19017c')) {
                            sendSignUpEventHuffson(res?.data?.user);
                        }

                        /** Arslan */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_44286b_19017c')) {
                            sendSignUpEventArslan(res?.data?.user);
                        }

                        /** Netherlands */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_44545b_19017c')) {
                            sendSignUpEventSomeAffiliate(res?.data?.user);
                        }

                        /** Playcash */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_44390b_19017c') ||
                            res?.data?.user?.btag?.includes('playcash_ca_fb_ios') ||
                            res?.data?.user?.btag?.includes('playcash_ca_fb_android')
                        ) {
                            sendSignUpEventPlayCash(res?.data?.user);
                        }

                        /** NGR */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('ngr_ca_fbmix') ||
                            res?.data?.user?.btag?.includes('ngr_ca_aso')
                        ) {
                            sendSignUpEventNGR(res?.data?.user);
                        }

                        /** All in */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('allin_ca_fbmix')) {
                            sendSignUpEventAllIn(res?.data?.user);
                        }

                        /** Black ASO */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('blackaso_ca_aso_android')) {
                            sendSignUpEventBlackASO(res?.data?.user);
                        }

                        /** GulfStream */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('gulfstream_ca_fbpwa')) {
                            sendSignUpEventGulfStream(res?.data?.user);
                        }

                        /** Traffgrunt */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('traffgrunt_ca_fbpwa')) {
                            sendSignUpEventTraffgrunt(res?.data?.user);
                        }

                        /** Traffbraza */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('traffbraza_ca_fbpwa')) {
                            sendSignUpEventTraffbraza(res?.data?.user);
                        }

                        /** CPA Crew */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_44602b_19017c')) {
                            sendSignUpEventIAffiliate(res?.data?.user);
                        }

                        /** Magic Click */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_43396b_19017c')) {
                            sendSignUpEventMagicClick(res?.data?.user);
                        }

                        /** Offergate */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_44657b_19017c')) {
                            sendSignUpEventOfferGate(res?.data?.user);
                        }

                        /** YepAds */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_44640b_19017c')) {
                            sendSignUpEventYepAds(res?.data?.user);
                        }

                        /** YepAds RS */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('ypds00437RS')) {
                            sendSignUpEventYepAds(res?.data?.user);
                        }

                        /** Masson Partners */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('mssnprtrs4385')) {
                            sendSignUpEventMassonPartners(res?.data?.user);
                        }

                        /** Adkey */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_44854b_19077c')) {
                            sendSignUpEventAdkey(res?.data?.user);
                            sendFBPixelAdkey();
                        }

                        /** C3PA */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('c3pa33890')) {
                            sendSignUpEventC3PA(res?.data?.user);
                        }

                        /** Oksana */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('a_43426b_19077c')) {
                            sendSignUpEventOksana(res?.data?.user);
                        }

                        /** CPA_BROS */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('cpab4390455')) {
                            sendSignUpEventCPA_BROS(res?.data?.user);
                        }

                        /** Triangle */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('tria673499')) {
                            sendSignUpEventTriangle(res?.data?.user);
                        }

                        /** Gamebeat */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('gamb88490')) {
                            sendSignUpEventGamebeat(res?.data?.user);
                        }

                        /** Sharklink */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('shark3487')) {
                            sendSignUpEventSharklink(res?.data?.user);
                        }

                        /** Traffic Devils */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('tfd66653')) {
                            sendSignUpEventTFD(res?.data?.user);
                        }

                        /** Quad */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('quad777')) {
                            sendSignUpEventQuad(res?.data?.user);
                        }

                        /** AFFPRO */
                        /** step 3 */
                        if (res?.data?.user?.btag?.includes('affpro32551')) {
                            sendSignUpEventAFFPRO(res?.data?.user);
                        }

                    } catch (error) {
                        console.log(`Error, registration postbacks, ${error}`);
                    }

                    dispatch(updateUser({
                        isLoggedIn: true,
                        data: res.data.user
                    }));

                    dbSendWelcomeEmail();

                    setTimeout(() => {
                        window.location.href = `${window.location.origin}/${locale}?quick-deposit`
                    }, 1000);

                }).catch(err => {
                    setErrors([...err.response.data.errors]);
                });
            }).catch(err => {
                setErrors([...err.response.data.errors]);
            });
        }
    }

    const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

    //useEffect(() => { console.log(countries) })

    return (
        <>
            <Helmet>
                <title>Register | Cherry Spins Casino</title>
            </Helmet>

            <Promotion />

            {/* Errors */}
            <div className="form-wrapper">
                {
                    errors.map((error) => {
                        return (
                            <Alert key={uniqid()} variant="danger" className="mb-1 mt-3" onClose={() => setErrors([])} dismissible >
                                {error.message}
                                {
                                    !error.data ? null :
                                        error.data.map((item) => <p key={uniqid()} className='mb-0 mt-3'>{item.message}</p>)
                                }
                            </Alert>
                        )
                    })
                }
            </div>

            <div className="form-wrapper form-wrapper-background">
                {/* Progress bar */}
                <div className="progress-container">
                    <div className="progress-bar step-email" ref={progressBar}>
                        <div className="completed-bar"></div>
                    </div>
                </div>
                {
                    {
                        email: <EmailStep {...{
                            email,
                            setEmail,
                            isValidEmail,
                            isValidForm,
                            toNextStep,
                            isLoading,
                            refEmail
                        }} />,
                        password: <PasswordStep {...{
                            password,
                            setPassword,
                            toNextStep,
                            toPreviousStep,
                            isLoading,
                            refPassword
                        }} />,
                        name: <NameStep {...{
                            fullName,
                            setFullName,
                            toNextStep,
                            toPreviousStep,
                            isLoading,
                            refName
                        }} />,
                        country: <CountryStep {...{
                            countrySelect,
                            setCountrySelect,
                            countries,
                            setCountries,
                            setState,
                            toNextStep,
                            toPreviousStep,
                            isLoading,
                            refCountry
                        }} />,
                        phone: <PhoneStep {...{
                            phone,
                            setPhone,
                            toNextStep,
                            toPreviousStep,
                            isLoading,
                            refPhone,
                            countrySelect,
                            countries
                        }} />,
                        addressAutoCanada: <AddressAutoCanada {...{
                            getAddressFromLookup,
                            toPreviousStep,
                            toNextStep,
                            refAddressAutoCanada
                        }} />,
                        addressManualCanada: <AddressManualCanada {...{
                            street,
                            setStreet,
                            city,
                            setCity,
                            state,
                            setState,
                            zip,
                            setZip,
                            isLoading,
                            toPreviousStep,
                            toNextStep,
                            refAddressManualCanada
                        }} />,
                        addressManualWorld: <AddressManualWorld  {...{
                            street,
                            setStreet,
                            city,
                            setCity,
                            state,
                            setState,
                            zip,
                            setZip,
                            isLoading,
                            toPreviousStep,
                            toNextStep,
                            refAddressManualWorld
                        }} />,
                        completeRegistration: <CompleteRegistration {...{
                            register,
                            isLoading,
                            toPreviousStep,
                            toNextStep,
                            refCompleteRegistration,
                            countrySelect
                        }} />,
                        registrationSuccess: <RegistrationSuccess />
                    }[step]
                }
            </div>
        </>
    );
}

function StepHeader({ text, btnBackHandler }) {
    return (
        <div className="step-header">
            <Button className="btn-secondary btn-back" onClick={btnBackHandler} value="">
                <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <h2 className="step-title">{text}</h2>
        </div>
    )
}

function EmailStep({ email, setEmail, isValidEmail, isValidForm, toNextStep, isLoading, refEmail }) {
    function continueHandler() {
        toNextStep('password', refEmail);
        voluumSendPostback('reg-start');
        FBRegistrationInit();
    }

    function FBRegistrationInit() {
        try {
            window.FbEvents.init().then(data => {
                console.log(data)
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refEmail}>
            <div className="step-header" style={{ height: '35px' }}>
                <h2 className="step-title">Email</h2>
            </div>

            <Form.Group controlId="email">
                <Form.Control
                    required
                    className="registration-field"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    isInvalid={isValidForm && !isValidEmail(email)}
                    isValid={isValidEmail(email)} />
                <Form.Control.Feedback type="invalid">Please, enter a valid email.</Form.Control.Feedback>
            </Form.Group>

            {
                email.length > 2 && !email.includes('@') && (
                    <div className="mt-3 mb-2">
                        <Button className="btn-secondary me-1" onClick={() => setEmail(email + '@gmail.com')}>@gmail.com</Button>
                        <Button className="btn-secondary" onClick={() => setEmail(email + '@yahoo.com')}>@yahoo.com</Button>
                    </div>
                )
            }

            <div className="step-footer mb-3">
                {
                    email.length === 0 && (
                        <>
                            <img src={arrowEmail} className="registration-arrow-email" />
                            <p style={{ fontSize: "12px" }} className="disabled-text">Example: john@gmail.com</p>
                        </>

                    )
                }
                {
                    isValidEmail(email) && (
                        <Button className="btn-main btn-next animate__animated animate__faster animate__fadeIn"
                            disabled={isLoading}
                            onClick={continueHandler}
                        >
                            {
                                isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                            }
                            <span>Continue</span>
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

function PasswordStep({ password, setPassword, toPreviousStep, toNextStep, isLoading, refPassword }) {
    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refPassword}>
            <StepHeader
                text="Set your password"
                btnBackHandler={() => { toPreviousStep('email') }}
            />

            <Form.Group controlId="password">
                <Form.Control
                    required
                    className="registration-field"
                    placeholder="Enter your password"
                    type="password"
                    value={password}
                    isValid={password.length > 6}
                    onChange={e => setPassword(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please enter your password.</Form.Control.Feedback>
            </Form.Group>

            <div className="step-footer mb-3">
                {
                    password.length <= 6 && (
                        <p style={{ fontSize: "12px" }} className="disabled-text">You password must be at least 7 characters long.</p>
                    )
                }
                {
                    password.length > 6 && (
                        <Button className="btn-main btn-next animate__animated animate__faster animate__fadeIn"
                            disabled={isLoading}
                            onClick={() => { toNextStep('name', refPassword) }}
                        >
                            {
                                isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                            }
                            <span>Continue</span>
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

function NameStep({ fullName, setFullName, toPreviousStep, toNextStep, isLoading, refName }) {
    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refName}>
            <StepHeader
                text="Enter your full name"
                btnBackHandler={() => { toPreviousStep('password') }}
            />

            <Form.Group controlId="fullName">
                <Form.Control
                    required
                    type="text"
                    value={fullName}
                    className="registration-field"
                    placeholder="Enter First and Last name"
                    onChange={e => setFullName(e.target.value)}
                    isValid={fullName.length > 3} />
                <Form.Control.Feedback type="invalid">Please enter your first and last name.</Form.Control.Feedback>
            </Form.Group>

            <div className="step-footer mb-3">
                {
                    fullName.length > 3 && (
                        <Button className="btn-main btn-next animate__animated animate__faster animate__fadeIn"
                            disabled={isLoading}
                            onClick={() => { toNextStep('country', refName) }}
                        >
                            {
                                isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                            }
                            <span>Continue</span>
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

function CountryStep({ countrySelect, setCountrySelect, countries, setCountries, setState, toPreviousStep, toNextStep, isLoading, refCountry }) {
    function continueHandler() {
        if (countrySelect === 'Canada') {
            setState('AB');
        }
        else {
            setState('');
        }

        toNextStep('phone', refCountry);
    }

    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refCountry}>
            <StepHeader
                text="Choose your country"
                btnBackHandler={() => { toPreviousStep('name') }}
            />

            <FetchCountries setCountries={setCountries} />

            <InputGroup>
                <InputGroup.Text className="registration-field-country-flag">
                    <img src={countries.filter(country => country.name === countrySelect)[0]?.flag} />
                </InputGroup.Text>
                <Form.Select
                    aria-label="Country"
                    className="registration-field form-select-country"
                    value={countrySelect}
                    onChange={e => setCountrySelect(e.target.value)}
                >
                    {
                        countries.map((country) => (<option key={uniqid()} value={country?.name}>{country?.name}</option>))
                    }
                </Form.Select>
            </InputGroup>

            <div className="step-footer mb-3">
                {
                    countrySelect && (
                        <Button className="btn-main btn-next animate__animated animate__faster animate__fadeIn"
                            disabled={isLoading}
                            onClick={continueHandler}
                        >
                            {
                                isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                            }
                            <span>Continue</span>
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

function PhoneStep({ phone, setPhone, toPreviousStep, toNextStep, isLoading, refPhone, countrySelect, countries }) {
    function continueHandler() {
        if (countrySelect === 'Canada') {
            toNextStep('addressAutoCanada', refPhone);
            return;
        }
        toNextStep('addressManualWorld', refPhone);
    }

    function formatNumber(value) {
        // returns: "x", "xx", "xxx"
        if (value.length < 4) return value;
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        if (value.length < 7) return `(${value.slice(0, 3)}) ${value.slice(3)}`;
        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }

    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refPhone}>
            <StepHeader
                text="Set mobile phone"
                btnBackHandler={() => { toPreviousStep('country') }}
            />

            <InputGroup id="phone" name="phone" style={{ marginTop: '-7px' }}>
                <InputGroup.Text className="registration-field-phone-code">
                    {
                        countries.filter(country => country.name === countrySelect)[0]?.phoneCode
                    }
                </InputGroup.Text>
                <Form.Control
                    type="text"
                    maxLength="14"
                    //value={formatNumber(phone)}
                    value={phone}
                    className="registration-field"
                    placeholder="Enter your mobile phone"
                    isValid={phone.length > 7}
                    onChange={e => setPhone(e.target.value.replace(/[^\d]/g, ''))} />
            </InputGroup>

            <div className="step-footer mb-3">
                {
                    phone.length > 7 && (
                        <Button className="btn-main btn-next animate__animated animate__faster animate__fadeIn"
                            disabled={isLoading}
                            onClick={continueHandler}
                        >
                            {
                                isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                            }
                            <span>Continue</span>
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

function AddressAutoCanada({ getAddressFromLookup, toPreviousStep, toNextStep, refAddressAutoCanada }) {
    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refAddressAutoCanada}>
            <StepHeader
                text="Start typing your address"
                btnBackHandler={() => { toPreviousStep('phone') }}
            />

            <AddressLookupRegForm {...{ getAddressFromLookup }} />

            {/* Navigation buttons */}
            <div className="step-footer mb-3 mt-2">
                <p style={{ fontSize: "12px", marginBottom: "30px", textAlign: "center" }} className="disabled-text">Start with a street number <br /> (ex: 20 Yorkland ST, Edmonton, Alberta)</p>
                <p className="mb-0">Did not find your address?</p>
                <Button variant="link" className="high-emphasis" onClick={() => { toNextStep('addressManualCanada', refAddressAutoCanada) }} style={{ fontSize: '14px' }}>Enter manually</Button>
            </div>
        </div>
    )
}

function AddressManualCanada({ street, setStreet, city, setCity, state, setState, zip, setZip, isLoading, toPreviousStep, toNextStep, refAddressManualCanada }) {
    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refAddressManualCanada}>
            <StepHeader
                text="Confirm your details"
                btnBackHandler={() => { toPreviousStep('addressAutoCanada') }}
            />

            <div className="mb-3 mt-3">
                <FloatingLabel label="Street (Ex. 7 Lucky St)" className="mb-2">
                    <Form.Control
                        maxLength="100"
                        type="text"
                        value={street}
                        className="registration-field-address"
                        onChange={e => setStreet(e.target.value)} />
                </FloatingLabel>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="City (Ex. Calgary)">
                            <Form.Control
                                maxLength="50"
                                type="text"
                                value={city}
                                className="registration-field-address"
                                onChange={e => setCity(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please enter your city.</Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="pe-0">
                        <FloatingLabel label="Province">
                            <Form.Select
                                aria-label="Province (Canada)"
                                value={state}
                                onChange={e => setState(e.target.value)}
                                className="registration-field-address"
                            >
                                <option key={uniqid()} value="AB">AB</option>
                                <option key={uniqid()} value="BC">BC</option>
                                <option key={uniqid()} value="MB">MB</option>
                                <option key={uniqid()} value="NB">NB</option>
                                <option key={uniqid()} value="NL">NL</option>
                                <option key={uniqid()} value="NT">NT</option>
                                <option key={uniqid()} value="NS">NS</option>
                                <option key={uniqid()} value="NU">NU</option>
                                <option key={uniqid()} value="ON">ON</option>
                                <option key={uniqid()} value="PE">PE</option>
                                <option key={uniqid()} value="QC">QC</option>
                                <option key={uniqid()} value="SK">SK</option>
                                <option key={uniqid()} value="YU">YU</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel label="Postal code">
                            <Form.Control
                                maxLength="12"
                                type="text"
                                value={zip}
                                className="registration-field-address"
                                onChange={e => setZip(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please enter your postal code.</Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
            </div>

            <div className="step-footer mb-4">
                {
                    (!street || !city || !zip || !state) && (
                        <img src={arrowAddress} className="registration-arrow-address" />
                    )
                }
                {
                    street && city && state && zip && (
                        <>
                            <Button className="btn-main btn-next animate__animated animate__faster animate__fadeIn"
                                disabled={isLoading}
                                onClick={() => { toNextStep('completeRegistration', refAddressManualCanada) }}
                            >
                                {
                                    isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                                }
                                <span>Continue</span>
                            </Button>
                            <p style={{ fontSize: "12px", marginTop: "15px", marginBottom: "0px", textAlign: "center" }} className="disabled-text">By clicking "Confirm" I acknowledge that this is my current address in Canada.</p>
                        </>
                    )
                }
            </div>
        </div>
    )
}

function AddressManualWorld({ street, setStreet, city, setCity, state, setState, zip, setZip, isLoading, toPreviousStep, toNextStep, refAddressManualWorld }) {
    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refAddressManualWorld}>
            <StepHeader
                text="Confirm your details"
                btnBackHandler={() => { toPreviousStep('phone') }}
            />

            <div className="mb-3 mt-3">
                <FloatingLabel label="Street (Ex. 7 Lucky St)" className="mb-2">
                    <Form.Control
                        maxLength="100"
                        type="text"
                        value={street}
                        className="registration-field-address"
                        onChange={e => setStreet(e.target.value)} />
                </FloatingLabel>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="City (Ex. Calgary)">
                            <Form.Control
                                maxLength="50"
                                type="text"
                                value={city}
                                className="registration-field-address"
                                onChange={e => setCity(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please enter your city.</Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="pe-0">
                        <FloatingLabel label="State">
                            <Form.Control
                                type="text"
                                value={state}
                                className="registration-field-address"
                                onChange={e => setState(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please enter your state.</Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel label="Postal code">
                            <Form.Control
                                maxLength="12"
                                type="text"
                                value={zip}
                                className="registration-field-address"
                                onChange={e => setZip(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please enter your postal code.</Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
            </div>

            <div className="step-footer mb-4">
                {
                    (!street || !city || !zip || !state) && (
                        <img src={arrowAddress} className="registration-arrow-address" />
                    )
                }
                {
                    street && city && state && zip && (
                        <>
                            <Button className="btn-main btn-next animate__animated animate__faster animate__fadeIn"
                                disabled={isLoading}
                                onClick={() => { toNextStep('completeRegistration', refAddressManualWorld) }}
                            >
                                {
                                    isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                                }
                                <span>Continue</span>
                            </Button>
                            <p style={{ fontSize: "12px", marginTop: "15px", marginBottom: "0px", textAlign: "center" }} className="disabled-text">By clicking "Confirm" I acknowledge that this is my current address.</p>
                        </>
                    )
                }
            </div>
        </div>
    )
}

function CompleteRegistration({ register, isLoading, toPreviousStep, toNextStep, refCompleteRegistration, countrySelect }) {
    function previousHandler() {
        if (countrySelect === 'Canada') {
            toPreviousStep('addressManualCanada');
            return;
        }
        toPreviousStep('addressManualWorld');
    }

    function FBRegistrationComplete() {
        try {
            window.FbEvents.CompleteRegistration('CompleteRegistration', 0, true).then(data => {
                console.log(data)
            });
        } catch (error) {
            console.log(error);
        }
    }

    function continueHandler() {
        toNextStep('registrationSuccess', refCompleteRegistration);
        FBRegistrationComplete();
        setTimeout(() => { register(); }, 1500);
        voluumSendPostback('reg-finish');
    }
    return (
        <div className="animate__animated animate__faster animate__fadeIn" ref={refCompleteRegistration}>
            <StepHeader
                text="Terms and Conditions"
                btnBackHandler={previousHandler}
            />

            <p className="high-emphasis mt-3">By clicking "Complete" you are agreeing to:</p>
            <ul>
                <li >
                    <span className="high-emphasis">Cherry Spins casino <a href="https://www.cherryspins.support/terms-and-conditions" target="_blank">terms and conditions</a>.</span>
                </li>
                <li className="high-emphasis">
                    <span>That you are currently 18 or more years of age.</span>
                </li>
            </ul>

            {/* Navigation buttons */}
            <div className="step-footer mb-3">
                {
                    true && (
                        <Button className="btn-main btn-next animate__animated animate__faster animate__fadeIn"
                            onClick={continueHandler}
                            disabled={isLoading}
                        >
                            {
                                isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                            }
                            <span>Continue</span>
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

function RegistrationSuccess() {
    return (
        <div className="animate__animated animate__faster animate__fadeIn">
            <h3 className="h4 text-center">Success!</h3>
            <p className="text-center">Your amazing journey begins now.</p>
            <div className="register-final-loading-wrapper">
                <Loading color="white" />
            </div>
        </div>
    )
}

function FetchCountries({ setCountries }) {
    useEffect(async () => {
        try {
            const response = await dbGetCountries();

            if (response.data.docs.length > 0) {
                setCountries(response.data.docs);
            }
        } catch (error) {
            console.log(error);
        }
    }, [])

    return null;
}


function Promotion() {
    const location = useLocation();

    const [game, setGame] = useState(null);
    const [promotion, setPromotion] = useState(null);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {

        if (location?.search?.includes('game=')) {
            const id = location.search.split('&')[1].split('game=')[1];

            if (id && !game && !fetching) {
                setFetching(true);
                dbGetGame(id)
                    .then(res => {
                        if (!res.data.docs[0]) { return; }
                        setGame(res.data.docs[0]);
                    })
            }
        }

        if (location?.search?.includes('promo=')) {
            const id = location.search.split('&')[1].split('promo=')[1];

            if (id && !promotion && !fetching) {
                setFetching(true);
                dbGetPromotion(id)
                    .then(res => {
                        if (!res.data.docs[0]) { return; }
                        setPromotion(res.data.docs[0]);
                    })
            }
        }
    }, [game, promotion]);

    return (
        <>
            {!game && !promotion && (location?.search?.includes('game=') || location?.search?.includes('promotion=')) && (
                <div className="promotion-wrapper" >
                    <p className="mb-0">Loading</p>
                </div>
            )}

            {!game && !promotion && !location?.search?.includes('game=') && !location?.search?.includes('promotion=') && (
                <div className="promotion-wrapper" >
                    <img src="https://cherryspins.b-cdn.net/bonuses/no-deposit-10FS-7-monkeys-registration.png" className="me-3" alt="" />
                    <div>
                        <p className="mb-0">Complete your registration and get <b>10&nbsp;free&nbsp;spins</b>!</p>
                    </div>
                </div>
            )}

            {promotion && (
                <div className="promotion-wrapper">
                    <img src={getImageSrc(promotion.image)} className="me-3" alt="" />
                    <div>
                        <p className="mb-0">{promotion?.subtitle}</p>
                    </div>
                </div>
            )}

            {game && (
                <div className="promotion-wrapper">
                    <img src={getImageSrc(game.image)} className="me-3" alt="" />
                    <div>
                        <p className="mb-0">Play <b>{game?.name}</b> in less than a minute!</p>
                    </div>
                </div>
            )}
        </>
    )
}